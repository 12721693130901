<script setup>
import { ref } from 'vue';
import { useStore } from 'vuex';

const email = ref('nicsan@capssoft.com');
const password = ref('nicsan@capssoft.com');
const loading = ref(false);

const store = useStore();

const getFormData = async () => {
    loading.value = true;

    await store.dispatch('authentificate', {
        email: email.value,
        password: password.value
    });

    loading.value = false;
};

</script>


<template>
    <form action="#" @submit.prevent="getFormData" method="POST">

        <h1>Login</h1>

        <p v-if="store.state.authMessage !== ''">
            {{ store.state.authMessage }}
        </p>

        <input type="text" placeholder="Email" class="form-control" autocomplete="off" v-model="email">

        <input type="password" placeholder="Password" class="form-control" autocomplete="off" v-model="password">

        <button type="submit" :disabled="loading">
            Login
        </button>

    </form>
</template>

<style scoped>
form {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    max-width: 400px;
}

.form-control {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 7px;
    border: 1px solid #ddd;
    width: auto;
}

button {
    display: inline-block;
    width: 100%;
    padding: 10px 20px;
    border-radius: 7px;
    border: 1px solid #ddd;
    cursor: pointer;
}

input[type="text"],
input[type="tel"] {
    background-color: #fff;
    color: #000;
}
</style>
