
<script setup>
import Projects from './Projects.vue';
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const router = useRouter();
const store = useStore();

onMounted(() => {
    if (!store.state.authentificated) {
        router.push({ name: 'admin' });
    }
});
</script>

<template>
    <section class="projects-admin">
        <div class="container">
            <div class="projects-admin__wrapper">
                <h1>
                    Projects
                </h1>
                <router-link class="projects-admin__btn" to="/admin/project/create">
                    Create new project
                </router-link>
            </div>
            <Projects />
        </div>
    </section>
</template>

<style>
.projects-admin {
    width: 100%;
}

.projects-admin__btn {
    display: inline-block;
    color: #fff;
    text-decoration: none;
    padding: 12px;
    background-color: #000;
    border-radius: 7px;
    border: 1px solid #000;
    transition: all 0.3s ease;
}

.projects-admin__btn:hover {
    background-color: #fff;
    color: #000;
}

.projects-admin__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    width: 100%;
}

.projects-admin__items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
}

.projects-admin__item {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.projects-admin__item-wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.projects-admin__item-img {
    width: 100%;
    height: 300px;
    overflow: hidden;
    border-radius: 7px;
}

.projects-admin__item-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.projects-admin__item-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.projects-admin__item-btns {
    display: flex;
    justify-content: space-between;
}

.projects-admin__item-btns>* {
    cursor: pointer;
    font-size: 14px;
}

.projects-admin__item-btns>*:disabled {
    cursor: not-allowed;
    background-color: gray;
    color: #fff;
}

@media screen and (max-width: 1200px) {
    .projects-admin__items {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 992px) {
    .projects-admin__items {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (max-width: 768px) {
    .projects-admin__item-img {
        height: 200px;
    }
}

@media screen and (max-width: 576px) {
    .projects-admin__item-img {
        height: 150px;
    }
}

@media screen and (max-width: 480px) {
    .projects-admin__item-img {
        height: 100px;
    }
}

@media screen and (max-width: 360px) {
    .projects-admin__item-img {
        height: 80px;
    }
}

@media screen and (max-width: 320px) {
    .projects-admin__item-img {
        height: 60px;
    }
}

@media screen and (max-width: 280px) {
    .projects-admin__item-img {
        height: 40px;
    }
}

@media screen and (max-width: 240px) {
    .projects-admin__item-img {
        height: 20px;
    }
}

@media screen and (max-width: 200px) {
    .projects-admin__item-img {
        height: 10px;
    }
}
</style>