<script setup>
import { useRoute } from 'vue-router';
import { computed } from 'vue';

const year = new Date().getFullYear();

const route = useRoute();

const isAdmin = computed(() => route.name?.toLowerCase()?.includes('admin'));

</script>

<template>
  <section class="section1" v-if="!isAdmin">

    <router-link to="/">
      <img class="logo" src="@/assets/img/logo.png">
    </router-link>

    <div class="topnav">
      <router-link to="/">Acasă</router-link>
      <router-link to="/courses">Cursuri IT</router-link>
      <router-link to="/computer-networks">Reţele de calculatoare</router-link>
      <router-link to="/surveillance-cameras">Camere de supraveghere</router-link>
      <router-link to="/web-development">Dezvoltare WEB</router-link>
      <router-link to="/contacts">Contacte</router-link>
    </div>

  </section>

  <router-view />

  <footer v-if="!isAdmin">
    <p>Copyright CapsSoft Consulting {{ year }}</p>
  </footer>
</template>

<style>
@import url(@/assets/css/style.css);

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
