<script setup>

import Login from './Auth/Login.vue';
import IndexProject from './Projects/Index.vue';
import { useStore } from 'vuex';

const store = useStore();

</script>

<template>
    <div class="container">

        <Login v-if="!store.getters.authentificated" />

        <IndexProject v-if="store.getters.authentificated" />

    </div>
</template>

<style>
.container {
    max-width: 1140px;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    margin: 0 auto;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    padding: 20px 15px;
}

@media screen and (max-width:1200px) {
    .container {
        max-width: 960px;
    }
}

@media screen and (max-width:1024px) {
    .container {
        max-width: 992px;
    }
}

@media screen and (max-width:992px) {
    .container {
        max-width: 768px;
    }
}
</style>