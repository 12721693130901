<template>
    <section class="section_400">
        <h1 class="section_titles">Serviciile noastre</h1>
        <div class="columns">
            <div>
                <img class="service_icons" src="@/assets/img/services/web.png">
                <h1 class="service_title">Web Development</h1>
                <p>Creează Viitorul pe Web: Programare pentru Ideile Tale!</p>
                <p>Transformă conceptele în site-uri web uimitoare folosind limbaje precum HTML pentru structură, CSS
                    pentru
                    design și JavaScript pentru interactivitate. Întreaga ta viziune, într-o pagină web.</p>"
            </div>
            <div>
                <img class="service_icons" src="@/assets/img/services/security-camera.png">
                <h1 class="service_title">Camere de supraveghere</h1>
                <p>Păstrează Totul Sub Control: Camere de Supraveghere Avansate!</p>
                <p>Asigură-ți securitatea cu camere de supraveghere de înaltă tehnologie. Monitorizează și protejează
                    spațiile
                    tale în timp real.</p>
            </div>
            <div>
                <img src="@/assets/img/services/graduation.png">
                <h1 class="service_title">Cursuri IT</h1>
                <p>Descoperă Cursurile IT: Învață, Inovează, Excelază!</p>
                <p>Pas cu pas către expertiza IT: învață programare, design web sau securitate cibernetică într-un mediu
                    prietenos și interactiv. Realizează-ți potențialul în tehnologie!</p>"
            </div>
            <div>
                <img src="@/assets/img/services/network.png">
                <h1 class="service_title">Rețele de calculatoare</h1>
                <p>Conectează-te la Viitorul Digital: Rețele de Calculatoare pentru O Lume Mai Interconectată!"</p>
                <p>Descoperă puterea rețelelor de calculatoare - infrastructura invizibilă ce ne unește și ne înnoiește
                    modul de
                    a trăi. De la comunicare instantanee la accesul la informații globale, rețelele de calculatoare
                    deschid uși
                    către o lume în care totul este la îndemâna ta. Alătură-te revoluției digitale astăzi!</p>
            </div>
        </div>
    </section>
</template>