<script setup>
import Project from '@/views/Components/Project.vue';

import { useStore } from 'vuex';
import { onMounted, ref } from 'vue';

const store = useStore();
const projects = ref([]);

onMounted(async () => {
    await store.dispatch('getProjects');
    projects.value = store.getters.activeProjects;
});

</script>


<template>
    <section class="projects">
        <div class="container">
            <div class="row">
                <div class="projects__title">
                    <h2 class="projects__title">Projects</h2>
                </div>
            </div>
            <div class="row">
                <div class="projects__wrapper">
                    <Project v-for="project in projects" :key="project.id" :project="project" />
                </div>
            </div>
        </div>
    </section>
</template>