<script setup>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const router = useRouter();
const store = useStore();

onMounted(() => {
    if (!store.state.authentificated) {
        router.push({ name: 'admin' });
    }
});


const title = ref('');
const shortDescription = ref('');
const description = ref('');
const active = ref(false);
const file = ref(null);
const loading = ref(false);

const createProject = async () => {
    loading.value = true;

    const formData = new FormData();

    formData.append('name', title.value);
    formData.append('short_description', shortDescription.value);
    formData.append('description', description.value);
    formData.append('is_active', active.value ? 1 : 0);
    formData.append('file', file.value);

    await store.dispatch('createProject', {
        formData: formData
    });

    loading.value = false;
    router.push({ name: 'admin' });
}

const fileSelected = (event) => {
    file.value = event.target.files[0];
}
</script>

<template>
    <div class="container">
        <form action="#" @submit.prevent="createProject" enctype="multipart/form-data">

            <h1>Create Post</h1>

            <input type="text" placeholder="Title" class="form-control" autocomplete="off" v-model="title">

            <textarea placeholder="Short Description" class="form-control" v-model="shortDescription"></textarea>

            <textarea placeholder="Description" class="form-control" v-model="description"></textarea>

            <label>
                Active:
                <input type="checkbox" v-model="active" :value="active">
            </label>

            <input type="file" class="form-control" v-on:change="fileSelected">

            <button type="submit" :disabled="loading">
                Create
            </button>

        </form>
    </div>
</template>

<style scoped>
* {
    user-select: none;
}

form {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    max-width: 400px;
}

label {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    cursor: pointer;
    color: #000;
}

input[type="text"],
input[type="tel"] {
    background-color: #fff;
    color: #000;
}

.form-control {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 7px;
    border: 1px solid #ddd;
    width: auto;

    resize: none;
}

textarea.form-control {
    height: 100px;
}

button {
    display: inline-block;
    width: 100%;
    padding: 10px 20px;
    border-radius: 7px;
    border: 1px solid #ddd;
    cursor: pointer;
}
</style>