import axios from "axios";
import url from "@/config";

const token = await axios.post(`${url}auth/login`, {
    email: 'nicsan@capssoft.com',
    password: 'nicsan@capssoft.com'
}).then((response) => response.data.token).catch((error) => console.log(error));

const instance = axios.create({
    baseURL: url,
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
    }
});

export const projects = {
    state: {
        projects: []
    },
    getters: {
        activeProjects(state) {
            return state.projects.filter((project) => project.is_active);
        },
        projects(state) {
            return state.projects;
        }
    },
    mutations: {
        setProjects(state, projects) {
            state.projects = projects;
        }
    },
    actions: {
        async getProjects({ state, commit }) {
            await instance.get('project').then((response) => {
                commit('setProjects', response.data.data);
            });
        },
        async getProject({ state, commit }, payload) {

            return await instance.get(`project/${payload}`).then((response) => {
                return response.data.data;
            });
        },
        async createProject({ state, commit }, payload) {

            await axios.post(`${url}project`, payload.formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization': 'Bearer ' + token
                }
            }).then((response) => {
                commit('setProjects', [...state.projects, response.data.data]);
            });

        },
        async deleteProject({ state, commit }, payload) {
            await instance.delete(`project/${payload.id}`).then((response) => {
                commit('setProjects', state.projects.filter((project) => project.id !== payload.id));
            });
        },
        async updateProject({ state, commit }, payload) {

            await axios.post(`${url}project/${payload.id}`, payload.formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json',
                }
            }).then((response) => {
                commit('setProjects', state.projects.map((project) => {
                    if (project.id === payload.id) {
                        project = response.data.data;
                    }
                    return project;
                }));
            });
        },
    },
}
