<script setup>
import Project from './Project.vue';

import { onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const projects = ref([]);

onMounted(async () => {
    await store.dispatch('getProjects');

    projects.value = store.getters.projects;
});

watch(() => store.getters.projects, (value) => {
    projects.value = value;
});
</script>

<template>
    <div class="projects-admin__items">
        <Project v-for="project in projects" :key="project.id" :project="project" />
    </div>
</template>