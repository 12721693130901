import { createRouter, createWebHistory } from 'vue-router'

import HomeIndex from '@/views/Home/Index.vue'
import ComputerNetworksIndex from '@/views/ComputerNetworks/Index.vue'
import ContactsIndex from '@/views/Contacts/Index.vue'
import CoursesIndex from '@/views/Courses/Index.vue'
import SurveillanceCamerasIndex from '@/views/SurveillanceCameras/Index.vue'
import WebDevelopmentIndex from '@/views/WebDevelopment/Index.vue'
import ProjectIndex from '@/views/Project/Index.vue'

import Admin from '@/views/Admin/Index.vue'
import ProjectCreateAdmin from '@/views/Admin/Projects/Create/Index.vue'
import ProjectEditAdmin from '@/views/Admin/Projects/Edit/Index.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeIndex
  },
  {
    path: '/computer-networks',
    name: 'computerNetworks',
    component: ComputerNetworksIndex
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: ContactsIndex
  },
  {
    path: '/courses',
    name: 'courses',
    component: CoursesIndex
  },
  {
    path: '/surveillance-cameras',
    name: 'surveillanceCameras',
    component: SurveillanceCamerasIndex
  },
  {
    path: '/web-development',
    name: 'webDevelopment',
    component: WebDevelopmentIndex
  },
  {
    path: '/project/:id',
    name: 'project',
    component: ProjectIndex
  },

  {
    path: '/admin',
    name: 'admin',
    component: Admin
  },
  {
    path: '/admin/project/create',
    name: 'projectCreateAdmin',
    component: ProjectCreateAdmin
  },
  {
    path: '/admin/project/edit/:id',
    name: 'projectEditAdmin',
    component: ProjectEditAdmin
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
