import { createStore } from 'vuex';
import { projects } from './projects.js';
import axios from 'axios';
import url from "@/config";

export default createStore({
  state: {
    authentificated: false,
    authMessage: ''
  },
  getters: {
    authentificated(state) {
      return state.authentificated;
    }
  },
  mutations: {
    setAuthentificated(state, status) {
      state.authentificated = status;
    },
    setAuthMessage(state, message) {
      state.authMessage = message;
    }
  },
  actions: {
    async authentificate({ state, commit }, payload) {

      if (typeof (payload) === 'undefined') {
        return;
      }

      await axios.post(`${url}auth/attempt`, {
        email: payload.email,
        password: payload.password
      }).then((response) => {
        if (response.data.status === true) {
          commit('setAuthentificated', true);
          commit('setAuthMessage', '');
        } else {
          commit('setAuthentificated', false);
          commit('setAuthMessage', response.data.message);
        }
      });
    }
  },
  modules: {
    projects: projects
  }
});
