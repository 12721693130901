<script setup>
import { defineProps } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
    project: {
        type: Object,
        required: true
    }
});

const store = useStore();
const { project } = props;

const deleteProject = async (event) => {
    event.target.disabled = true;
    const id = event.target.dataset.id;

    try {
        await store.dispatch('deleteProject', {
            id
        });
    } catch (error) {
        console.log(error);
    } finally {
        event.target.disabled = false;
    }

    await store.dispatch('getProjects');
};

</script>

<template>
    <div class="projects-admin__item">
        <div class="projects-admin__item-wrapper">
            <div class="projects-admin__item-img">
                <img :src="project.image" alt="">
            </div>
            <div class="projects-admin__item-content">
                <h2>
                    {{ project.name }}
                </h2>
                <p>
                    {{ project.short_description.substring(0, 100) + '...' }}
                </p>
                <div class="projects-admin__item-btns">
                    <router-link class="projects-admin__btn" :to="'/admin/project/edit/' + project.id">
                        Edit
                    </router-link>
                    <button class="projects-admin__btn" :data-id="project.id" @click="deleteProject">
                        Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>