<script setup>
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const router = useRouter();
const store = useStore();

const id = ref('');
const project = ref({});

onMounted(async () => {
    id.value = router.currentRoute.value.params.id;

    project.value = await store.dispatch('getProject', router.currentRoute.value.params.id);
});

</script>

<template>
    <section class="project">
        <div class="project__image">
            <img :src="project.image" alt="Project image">
        </div>
        <div class="project__content">
            <div class="project__title">
                <h2>{{ project.name }}</h2>
            </div>
            <div class="project__description">
                <p>{{ project.description }}</p>
            </div>
            <div class="project__published">
                <p>Publicat: {{ new Date(project.created_at).toLocaleString('en', {
                    timeZone: 'Europe/Moscow', year:
                        'numeric', month: 'numeric', day: 'numeric'
                }) }}</p>
            </div>
        </div>
    </section>
</template>

<style scoped>
.project {
    display: flex;
    justify-content: center;
    padding: 2rem;
}

.project__image {
    width: 50%;
    margin-right: 2rem;
    height: 600px;
}

.project__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.project__content {
    width: 50%;
    text-align: left;
}

.project__title {
    margin-bottom: 2rem;
}

.project__title h2 {
    font-size: 2rem;
    font-weight: 700;
}

.project__description {
    margin-bottom: 2rem;
}
</style>