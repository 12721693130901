<template>
    <section class="section_400" style="background-color: black; height:auto; padding: 30px;">
        <div class="form-container">
            <form id="myForm">
                <div class="form-group">
                    <label for="firstName">Prenume:</label>
                    <input type="text" id="firstName" name="firstName" required>
                </div>
                <div class="form-group">
                    <label for="lastName">Nume:</label>
                    <input type="text" id="lastName" name="lastName" required>
                </div>
                <div class="form-group">
                    <label for="phoneNumber">Număr de telefon:</label>
                    <input type="tel" id="phoneNumber" name="phoneNumber" pattern="[0-9]{9}" required>
                </div>
                <button type="submit" class="submit-button">Trimite</button>
            </form>
        </div>
    </section>
</template>